@import "variables";

.modal-dialog {
  padding: 0;
  width: 697px !important;
  max-width: 697px;
  border-radius: 10px;
}

.vertical-centered-modal{
  display: grid!important;
}

.vertical-centered-fit-content-modal-sm{
  display: grid!important;
  div.modal-dialog.modal-sm {
    min-width: 404px!important;
    width: unset!important;
    max-width: unset!important;
  }
}

@-webkit-keyframes expand{
  0%{top:100%}
  100%{top:0}
}

.top-modal {
  .modal-dialog {
    transform: none !important;
    transition: none !important;
    animation: none !important;

    .modal-content > * {
      top: 0;
      animation: expand .1s ease-out !important;
    }
  }
}

.modal-dialog.modal-sm {
  max-width: 404px !important;
  width: 404px !important;
}

.modal-dialog.modal-lg {
  max-width: 950px !important;
  width: 950px !important;
}

.modal-dialog.modal-xl {
  max-width: 1140px !important;
  width: 1140px !important;
}

.modal-dialog.modal-xxl {
  max-width: 1400px !important;
  width: 1400px !important;
}

.grid-container {
  gridster {
    display: flex;
    flex-direction: column;
    background: transparent;
    overflow: visible !important;

    gridster-item {
      background: transparent;
      overflow: visible;

      &.gridster-item-moving {
        box-shadow: none !important;
      }
    }

    gridster-preview {
      border-radius: 5px;
    }
  }
}

.be-item-label {
  color: #333333 !important;
}

.be-item-name .be-item-details {
  display: none;
}

.be-is-medium{
  border-radius: 5px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
}

.modal-content {
  margin-top: 0;
  border: 0;
}
.modal hr {
  border-top: 1px solid $color-background-light;
}

.alertify-logs {
  z-index: 9999;
  width: auto;
  border-radius:5px;
}
.alertify-logs>*{
  border-radius:5px;
}

/*documents options*/
.dataroom-container{
  .ReactModal__Content{
    border-radius:5px;
    input{
      box-shadow:none;
      width: 350px!important;
    }
  }
  label{
    div{
      font-size:18px;
      line-height: 24px;
      padding-bottom:15px;
    }
    .btn.btn-primary {
      margin-left: 0;
      margin-top: 20px;
      text-transform: uppercase;
      font-size: 12px;
      border-radius: 5px;
      letter-spacing: 0;
      /* padding: 15px 45px; */
      height: 50px;
    }
  }
  .be-modal-share{
    .be-modal-content{
      input{
        border-radius: 5px!important;
        border-right: 1px #ccc solid!important;
        width: 350px!important;
      }
    }
    .be-modal-btns{
      display:none;
    }
  }
  .be-modal-rename{
    .be-modal-content{
      input{
    width: 350px!important;
      }
    }
  }
  .be-modal-btns{
    .btn.btn-primary {
      margin-left: 0;
      text-transform: uppercase;
      font-weight: 700;
      padding: 0 45px;
      font-size:12px;
    }
    button:nth-child(2){
      margin: 0 0 0 15px;
      cursor: pointer;
      font-size: 12px;
      padding: 10px 24px;
      border: 1px solid $color-text-light!important;
      box-shadow: none!important;
      color: $color-text-default!important;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        color: white!important;
        background: #999999;
        border-color: #999999;
      }
    }
  }
  .bce-more-options{
    button{
      &:active{
        border: none;
        box-shadow:none;
        transition: none;
      }
      &:focus{
        border:none;
        box-shadow:none;
        transition: none;
      }
      &:hover{
        opacity:0.6;
      }
    }
  }
}

ngb-datepicker-month-view {
  .ngb-datepicker-navigation {
    align-items: baseline;
  }
  .ngb-dp-weekday {
      color: #333333;
  }
  .ngb-dp-day {
    outline: none !important;
  }

 [ngbDatepickerDayView]{
  border-radius: 50px;
 }

  .bg-primary {
    background-color: gray;
    border-radius: 50px;
  }
  &:hover{
    border-radius: 50px;
  }
  &:focus{
    outline: none;
  }
}

ngb-tooltip-window {
  .tooltip-inner {
    font-family: 'Lato', sans-serif;
    text-align: left !important;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
  /* Remove default */
  // -webkit-appearance: none;
}

.bp-btn {
  border-radius: 5px;
  width: 160px;
}

.bp-btn-loading-download {
  border-radius: 5px;
  width: 160px;
  background: $color-text-light;
  color: $color-text-dark;

  &:hover {
    background: $color-primary;
    color: #fff;
  }
}

.bp-controls .bp-page-num-wrapper {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-progress-bar {
  background-color: $color-primary;
}


.alertify-notifier {
  z-index: 9999 !important;
}

.modal {
  z-index: 9999;

  .modal-content {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 0 1px $color-box-shadow;

    .modal-footer {
      .btn {
        text-transform: uppercase;
      }
    }
  }
}
