
html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: $color-background-light;
  font-family: 'Lato', sans-serif !important;
  font-family: 'IntrumSans', sans-serif !important;
}

h3 {
  color: $color-primary;
}

.display-flex {
  display: flex;
  align-items: center;
}

.blockpanel {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(204, 204, 204, 1);
  width: 95%;
}

button {
  width: 100%;
  border-radius: 5px;
  text-align: center;
  background: $color-primary;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  border: none;
  box-shadow: none;
  height: 50px;
  line-height: 50px;
  outline: none !important;
  font-weight: 400;
  padding: 0 !important;

  &:hover {
    background: $color-primary-dark;
  }
  &.btn-outline {
    background: transparent;
    color: $color-primary;
    box-shadow: 0 0 0 1px $color-primary;
    &:hover {
      background: $color-primary;
      color: #fff;
    }
  }
  &[disabled] {
    opacity: .5;
    cursor: not-allowed;
  }
}

.btn.btn-default {
  cursor: pointer;
  font-size: 12px;
  color: $color-text-default;
  border: 1px solid $color-text-default;
  box-shadow: none!important;
  margin: 0;
  font-weight: 400;
  transition: all .15s ease-out;
  &:hover, &:active, &:focus {
    color: white!important;
    background: $color-text-default;
    border-color: $color-text-default;
  }
}

.btn.btn-primary {
  border: 1px $color-primary solid;
  background: transparent;
  color: $color-primary;
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  font-weight:400;
  &:hover {
    background: $color-primary;
    border-color: $color-primary;
    color:white;
  }
  &:active{
    background-color: $color-primary!important;
    border-color: $color-primary!important;
    color:white!important;
  }
  &:focus{
    background-color: $color-primary!important;
    border-color: $color-primary!important;
    color:white!important;
  }
}

.btn.btn-primary-bg {
  background: $color-primary;
  color: white;
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  font-weight:400;
  border-color: $color-primary;
  &:hover {
    opacity: .8;
    background: $color-primary;
    border-color: $color-primary;
    color:white;
  }
}

.btn.btn-nav {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, .2);
  background: white;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px 12px;
  font-size: 12px;
  color: $color-text-default;
  border: 1px white solid;
  transition: all .1s ease-out;
  &:hover {
    box-shadow: 0px 0px 14px rgba(0, 0, 0, .2);
    border-color: $color-primary;
  }
}

.btn-round {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none !important;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  transition: all .15s ease-out;

  &:hover{
    box-shadow: 0px 0px 0px 2px $color-primary;
  }

  &.primary {
    background: $color-primary;
    color: white;
    &:hover {
      background: $color-primary;
    }
  }
  &.default {
    background: #CECECE;
    color: $color-text-dark;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.btn-link {
  color: $color-primary;
  text-decoration: underline;;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: $color-primary;
  }
}

.avatars-ribbon {
  display:flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  .btn-round {

    /* &:hover{
      border: 2px $color-primary solid;
    } */
  }

  > * {
    .btn-round {
      margin-right: -12px;
    }
  }
}

main {
  width: calc(100% - #{$total-aside-width});
  margin: 0 35px 0 auto;
}

select{
  background: url(/assets/imgs/icons/ic-arrow-down.svg) no-repeat!important;
  background-size: 14px!important;
  background-position: 97% 50%!important;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.block {
  border-radius: 5px;
  background: white;
  /* width: calc(100% - #{$total-aside-width}); */
  margin: 0 auto;
  .block-header {
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: $color-text-dark;
      font-weight: bold;
      font-size: 18px;
    }
  }
}

.selector {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid $color-background-light;
  transition: all 0.1s ease-out;
  color: transparent;
  cursor: pointer;

  &:hover {
    border-color: $color-primary;
  }
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all .1s ease-in;
    transform: scale(0);
    background: $color-primary;
    z-index: 1;
    border-radius: 8px;
  }
  &.checked {
    border-color: $color-primary;
    color: white;
    &:before {
      transform: scale(1);
    }
    &:hover {
      border-color: $color-primary-dark;
      &:before {
        background: $color-primary-dark;
      }
    }
  }
  i {
    z-index: 2;
    font-size: 10px;
  }
}

.text-primary-color{
  color: $color-primary;
}

.disabled-by-role{
  pointer-events: none;
  opacity: .4!important;
}

.scrollable {
  &::-webkit-scrollbar {
    width: .5em;
    height: .5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .05);
    outline: 0px solid rgba(0, 0, 0, .05);
    transition: all .15s ease;

    &:hover {
      background-color: $color-text-default;
    }
  }

  &:hover {

    &::-webkit-scrollbar-thumb {
      background-color: $color-text-light;
      outline: 0px solid $color-text-light;
      transition: all .15s ease;

      &:hover {
        background-color: $color-text-default;
      }
    }
  }
}

.scrollable-dark {
  &::-webkit-scrollbar {
    width: .5em;
    height: .5em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .05);
    outline: 0px solid rgba(255, 255, 255, .05);
    transition: all .15s ease;

    &:hover {
      background-color: rgba(255, 255, 255, .15)
    }
  }

  &:hover {

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, .25);
      outline: 0px solid $color-text-light;
      transition: all .15s ease;

      &:hover {
        background-color: rgba(255, 255, 255, .35);
      }
    }
  }
}

.toggler {
  height: 21px;
  width: 83px;
  margin: 0 5px;
  border: 1px solid $color-text-light;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 2px 9px 2px 6px;
  font-size: 9px;
  color: $color-text-default;
  text-transform: uppercase;
  cursor: pointer;

  .expanded-indicator {
    margin-right: 6px;
    opacity: .6;
    transition: all .1s ease;
    width: 15px;
    height: 15px;
    background: url(/assets/imgs/deals/ic-arrow_right.svg);

    &.expanded {
      transform: rotate(90deg);
    }
  }
}

.add-button {
  font-size: 14px;
  color: $color-text-default;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;

  .btn-round {
    background: $color-primary url(/assets/imgs/deals/ic-plus-white.svg) no-repeat center;
    background-size: 15px;
    margin: 0 10px 0 15px;
    &:hover{
      background-color: $color-primary-dark;
      box-shadow: none;
    }
  }
  .add-text{
    font-size: 14px;
    color: $color-text-default;
    font-weight: normal;
    &:hover{
      cursor: pointer;
    }
  }
  @media (max-width: 1065px){
    &:not(.modal-button) {
      .add-text {
        display: none;
      }
    }
  }

  &:focus{
    outline: 0;
  }
}

.add-button.modal-button {
  display: flex;
  margin-bottom: 5px;
  .btn-round {
    margin-left: 0;
  }
}

.no-results {
  height: calc(
    100vh - #{$header-height} - #{$dashboard-selection-header-height} - #{$footer-height} - 55px
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-with-header-content {
  padding-top: $header-height;
  background-color: #fff;
  min-height: calc(100vh - #{$header-height});
}

.modal-footer {
  .btn {
    height: 50px;
    line-height: 50px;
    padding: 0 24px;
  }
}

.items-table__row {
  > *:not(:first-child) {
    padding-left: 9px;
  }
}

.status-dropdown {
  .status-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 6px;
    border-radius: 5px;
    transition: all .15s ease;
    width: 140px;

    &:hover {
      background-color: #fff
    }
  }
  .status-menu {
    border: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, .1);
  }

  .dropdown-item {
    cursor: pointer;

    &:active {
      background-color: $color-background-hover;
    }
  }
}
