/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'IntrumSans';
  src: url('assets/fonts/IntrumSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IntrumSans';
  src: url('assets/fonts/IntrumSans-Medium.ttf') format("truetype");
  font-weight: bold;
  font-style: normal;
}

@import "variables";
@import "styles/customs/main";
@import "styles/customs/contextmenu";
@import "layout";
