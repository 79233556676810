.ngx-contextmenu {

  ul {
    border-radius: 5px;
    box-shadow: 0px 1px 8px -1px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0,0, 0, .15);
    border: none;

    li {
      a {
        text-decoration: none;
        
        .item-wrapper {
          display: block;
          padding: 6px;
          color: $color-text-dark;

          &:hover {
            background: $color-background-hover;
            color: $color-primary;
          }

          &.disabled {
            opacity: .5;
            cursor: not-allowed;

              
            &:hover {
              background: transparent;
              color: $color-text-dark;
            }            
          }
        }
      }
    }
  }

}