// $color-primary: #debb78;
// $color-primary-dark: #b69962;
// $color-primary-dark-x: #977f52;
// $color-primary-light: #dec79c;
// $color-primary-light-x: #ded2bc;

$color-primary: #005969;
$color-primary-dark: #004652;
$color-primary-dark-x: #002c34;
$color-primary-light: #458995;
$color-primary-light-x: #82c1cc;

$color-text-light: #D2D4DD;
$color-text-default: #878b97;
$color-text-gray: #a3a3a3;
$color-text-table-stripe: #f9f9f9;
$color-text-dark: #14182D;
$color-text-white: #fff;

$color-background-light: #EBEFF4;

$color-background-light-1: #F3F5F8;
$color-background-light-2: #FBFBFB;
$color-background-medium: #E2E7EC;
$color-background-dark:#B2B5BA;

$color-border-light: #DAE4F0;
$color-border-light-alt: #EBEFF4;

$color-success: #00C30C;
$color-success-hover: #25a142;
$color-warning: #f29100;
$color-danger: #ff6f61;
$color-info: #43ccdf;
$color-closed: #11116f;

$color-purple: #9c88ff;

$color-background-hover: #E8F7F9;
$color-background-hover-dark: #CFEAEE;

$color-box-shadow: #E2E7EC;
$color-box-shadow-dark: #cecece;

$header-height: 70px;
$aside-width: 45px;
$total-aside-width: $aside-width * 2;
$footer-height: 90px;

// sizes
$dashboard-selection-header-height: 90px;

$size-height-button-medium: 45px;
$size-height-button-small: 40px;
$size-height-button-default: $size-height-button-medium;

$size-height-button-xl: 50px;
$size-height-button-lg: 45px;
$size-height-button-md: 40px;
$size-height-button-sm: 35px;
$size-height-button-xs: 30px;

$size-height-button-default: $size-height-button-md;

$size-avatar-spacing: 0.15rem;

// font
$font-size-small: 12px;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;

// breakpoints
$main-width-lg: 1230px;
$main-width-md: 1105px;
$main-width-sm: 980px;
$main-width-xs: 800px;

// components
$form-dropdown-color: $color-text-default;
$form-dropdown-border-color: $color-text-light;
$form-dropdown-border-hover-color: $color-primary;
$form-dropdown-background-color: transparent;
$form-dropdown-items-background-color: $color-primary;
$form-dropdown-arrows-color: $color-text-default;
